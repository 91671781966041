import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UserObject } from '../../models/data.models';
import { MyScriptService } from '../../services/myscript.service';
import { UserService } from '../../services/user.service';
import { LogoUrl, UserProfileType } from '../../settings/configs';
declare var $: any;

@Component({
  selector: 'app-agency-layout',
  templateUrl: './agency-layout.component.html',
  styleUrls: ['./agency-layout.component.css']
})
export class AgencyLayoutComponent implements OnInit, AfterViewInit {


  userInfo: UserObject;

  logoUrl = LogoUrl;

  isManager = false;
  constructor(@Inject(DOCUMENT) private document: Document, private userService: UserService, private myScriptService: MyScriptService) {

    this.userInfo = this.userService.userInfo;

    if (this.userInfo.profile_type_id == UserProfileType.GOVERNMENT) {
      this.isManager = true;
    }

    this.userService.UserSubject.subscribe((p: UserObject) => {
      this.userInfo = p;
      if (this.userInfo.profile_type_id == UserProfileType.GOVERNMENT) {
        this.isManager = true;
      }
    });
  }


  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.createCssLink('maintheme', 'argontheme.css');
    this.myScriptService.load('mainjs').then(data => {
      console.log('script loaded ', data);
    }).catch(error => console.log(error));
  }
  createCssLink(linkId, cssfile) {
    const headEl = this.document.getElementsByTagName('head')[0]

    const existEl = this.document.getElementById(linkId) as HTMLLinkElement;

    if (existEl) {
      existEl.href = cssfile;
    } else {
      const linkEl = this.document.createElement('link');

      linkEl.rel = 'stylesheet';
      linkEl.href = cssfile;
      linkEl.id = linkId;
      headEl.appendChild(linkEl);
    }

  }

  splitText(txt) {
    if (txt.length > 15) {
      txt = txt.substring(0, 15) + "...";
    }

    return txt;
  }


  logout() {
    this.userService.changeLogin();
  }
}
