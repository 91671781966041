import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
declare var md: any;
@Directive({
    selector: '[materialSidebarBackgroudDirective]',
})
export class MaterialSidebarBackgroudDirective {
    /*
    @Input('tagsinputValue')
    tagsinputValue: string;
*/
    elementT: ElementRef;

    constructor(el: ElementRef) {
        this.elementT = el;

        md.initSidebarsCheck();

        var window_width = $(window).width();

        // check if there is an image set for the sidebar's background
        md.checkSidebarImage();

        md.initMinimizeSidebar();

        // Multilevel Dropdown menu
    }

}