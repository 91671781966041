import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
declare var md: any;
@Directive({
    selector: '[materialAuthFullBGDirective]',
})
export class MaterialAuthFullBGDirective {

    elementT: ElementRef;

    constructor(el: ElementRef) {
        this.elementT = el;
       
        setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700);
    }

}