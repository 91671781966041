import { HttpHeaders } from '@angular/common/http';


export const UrlAPIAccount = 'https://longanapi.dgk.vn/api/account';
export const UrlAPI = 'https://longanapi.dgk.vn/api/agency';
export const APPLICATION_UID = '68e44a35-a524-11eb-833e-0050569c60ce';
export const UrlBlogAPI = 'https://longanapi.dgk.vn/api/blog';
export const LogoUrl = '/assets/logo_hatinh.png';

export class Constants {
    static readonly DATE_FMT = 'dd/MM/yyyy';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
}

export function httpOption(access_token: string, language_id = 1, captchaToken = '') {
    return {
        headers: new HttpHeaders({
            'x-client-language': String(language_id),
            'x-access-type': '1',
            'x-access-token': access_token,
            'x-client-random-key': '',
            'x-captcha-token': captchaToken,
            'x-client-uid': 'localhost',
            'x-application-uid': APPLICATION_UID
        })
    };
}


export const folder_images = 'test/';

export const USER_LOCAL_KEY = 'user_213123123';

export const reCATCHAKey = '6LekdYUaAAAAAGjtJ8kAOFWDZm6mv5X5I8Styrok';


export const CLIENT_INFO = {

}

