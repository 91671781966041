<!-- Sidenav -->
<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
    <div class="scrollbar-inner">
        <!-- Brand -->
        <div class="sidenav-header d-flex align-items-center">
            <a class="navbar-brand" *ngIf="isManager" routerLink="/">
              OCOP LONG AN
            </a>
            <a class="navbar-brand" *ngIf="!isManager" routerLink="/staff/">
                OCOP LONG AN
            </a>
            <div class="ml-auto">
                <!-- Sidenav toggler -->
                <div class="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main">
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="navbar-inner">
            <!-- Collapse -->
            <div class="collapse navbar-collapse " id="sidenav-collapse-main">
                <!-- Nav items -->
                <ul class="navbar-nav" *ngIf="isManager">
                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-dashboard" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-chart-pie-35 text-green"></i>
                            <span class="nav-link-text">DASHBOARDs</span>
                        </a>
                        <div class="collapse" id="navbar-dashboard">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/" class="nav-link">Tổng quan</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/dashboard/san-pham-ocop" class="nav-link">Báo cáo OCOP</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/dashboard/quantriso" class="nav-link">Quản trị số</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/dashboard/tem-truy-xuat" class="nav-link">Tem truy xuất</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-quanly" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-quanly">
                            <i class="ni ni-circle-08 text-warning"></i>
                            <span class="nav-link-text">QUẢN LÝ PHỤ TRÁCH</span>
                        </a>
                        <div class="collapse" id="navbar-quanly">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/manager/quan-ly" class="nav-link">Phụ trách cấp tỉnh</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/nhan-su" class="nav-link">Phụ trách cấp huyện</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-company" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">QUẢN LÝ NGƯỜI DÙNG</span>
                        </a>
                        <div class="collapse" id="navbar-company">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/manager/nguoi-dung" class="nav-link">Danh sách người dùng</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/tong-quan-nguoi-dung" class="nav-link">Report tổng quát</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/manager/san-pham">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">DANH SÁCH SẢN PHẨM</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/manager/ocophatinh/duyet-san-pham">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">DUYỆT SP OCOPLONGAN</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/manager/diem-phan-phoi">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">ĐIỂM PHÂN PHỐI</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: #9b9b9b;">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">VÙNG NGUYÊN LIỆU</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/manager/khao-sat">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">KHẢO SÁT ĐIỆN TỬ</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  href="#navbar-banhanh" data-toggle="collapse"
                            role="button" aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">TRUYỀN THÔNG</span>
                        </a>
                        <div class="collapse" id="navbar-banhanh">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/media/articles" class="nav-link">Tin tức truyền thông</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/media/documents" class="nav-link">Tài liệu văn bản</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-quanlytxng" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">Quản lý hệ thống TXNG</span>
                        </a>
                        <div class="collapse" id="navbar-quanlytxng">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/manager/tem-txng/mau-tem" class="nav-link">Mẫu tem lưu hành</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/tem-txng/don-nhap-tem" class="nav-link">Quản lý nhập tem
                                        TXNG</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/tem-txng/kho-tem" class="nav-link">Kho tem TXNG</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manager/tem-txng/kich-tem" class="nav-link">Duyệt tem TXNG</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav" *ngIf="!isManager">
                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-dashboard" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-chart-pie-35 text-green"></i>
                            <span class="nav-link-text">DASHBOARDs</span>
                        </a>
                        <div class="collapse" id="navbar-dashboard">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/staff/" class="nav-link">Tổng quan</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#navbar-company" data-toggle="collapse" role="button"
                            aria-expanded="false" aria-controls="navbar-dashboards">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">QUẢN LÝ NGƯỜI DÙNG</span>
                        </a>
                        <div class="collapse" id="navbar-company">
                            <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                    <a routerLink="/staff/nguoi-dung" class="nav-link">Danh sách người dùng</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/manager/san-pham">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">DANH SÁCH SẢN PHẨM</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" style="color: #9b9b9b;">
                            <i class="ni ni-folder-17 text-red"></i>
                            <span class="nav-link-text">VÙNG NGUYÊN LIỆU</span>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item p-3">
                        <img class="img-fluid" src="/assets/logo_ocop.png" alt="...">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<!-- Main content -->
<div class="main-content" id="panel">
    <!-- Topnav -->
    <nav class="navbar navbar-top navbar-expand navbar-dark bg-success border-bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <!-- Search form -->
                <!-- Navbar links -->
                <ul class="navbar-nav align-items-center ml-md-auto">
                    <li class="nav-item d-xl-none">
                        <!-- Sidenav toggler -->
                        <div class="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin"
                            data-target="#sidenav-main">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav align-items-center mr-auto">
                    <li class="nav-item pt-1">
                        <h3 class="text-white" style="font-weight: 700;text-transform: uppercase;">Tỉnh Long An
                        </h3>
                    </li>
                </ul>
                <ul class="navbar-nav align-items-center ml-auto ml-md-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <div class="media align-items-center" *ngIf="userInfo">
                                <div class="media-body ml-2 d-none d-lg-block">
                                    <span class="mb-0 text-sm  font-weight-bold">{{userInfo.email}}</span>
                                </div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="javascript:void(0)" (click)="logout()" class="dropdown-item">
                                <i class="ni ni-user-run"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Header -->
    <!-- Header -->
    <router-outlet></router-outlet>
</div>
