<nav class="float-left pt-2" >
    <ul>
        <li>
            <a *ngIf="languageCode === 'vi'" (click)="changeLanguage('en')" class="btn btn-social btn-fill btn-behance btn-sm"><i class="fa fa-globe" aria-hidden="true"></i>&nbsp;&nbsp;English</a>
            <a *ngIf="languageCode === 'en'" (click)="changeLanguage('vi')" class="btn btn-social btn-fill btn-behance btn-sm"><i class="fa fa-globe" aria-hidden="true"></i>&nbsp;&nbsp;Viet Nam</a>
        </li>
    </ul>
</nav>
<div class="copyright float-right">
    &copy;
    <a href="https://dgk.vn" target="_blank">Digital Kingdom JSC</a>
</div>