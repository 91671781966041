import { Component, OnInit } from '@angular/core';
import { DGKLanguageService } from '../../services/language.service';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.css']
})
export class AuthFooterComponent implements OnInit {
  languageCode: string;
  constructor(private languageService: DGKLanguageService) {
    this.languageCode = this.languageService.language_code;
  }

  ngOnInit(): void {
  }
  changeLanguage(code) {
    this.languageCode = code;
    this.languageService.changeLanguage(this.languageCode);
  }
}
