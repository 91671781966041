import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgencyLayoutComponent } from './layouts/agency-layout/agency-layout.component';
import { AuthAgencyLayoutComponent } from './layouts/auth-agency-layout/auth-agency-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'manager', pathMatch: 'full' },
  { path: 'auth', component: AuthAgencyLayoutComponent, loadChildren: () => import('./auths/auths.module').then(m => m.AuthsModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'manager', component: AgencyLayoutComponent, loadChildren: () => import('./agencies/agencies.module').then(m => m.AgenciesModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'staff', component: AgencyLayoutComponent, loadChildren: () => import('./agency-staff/agency-staff.module').then(m => m.AgencyStaffModule) },
  { path: 'media', component: AgencyLayoutComponent, loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: '**', redirectTo: 'agencies' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
