import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AuthNavbarComponent } from './components/auth-navbar/auth-navbar.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { MaterialAuthFullBGDirective } from './directives/material-auth-fullbackgroud.directive';
import { PopoverDirective } from './directives/popover.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainLeftsidebarComponent } from './components/main-leftsidebar/main-leftsidebar.component';
import { MaterialSidebarBackgroudDirective } from './directives/material-sidebar-backgroud.directive';
import { AgencyAuthNavbarComponent } from './components/agency-auth-navbar/agency-auth-navbar.component';

import { ApexChartComponent } from './components/apex-chart/apex-chart.component';
import { DateTimeNumberFormatPipe } from './settings/datetimenumberformat';
import { SortDirective } from './directives/sort.directive';
import { ListFilterPipe } from './pipes/listfilter.pipe';
import { DateNumberFormatPipe } from './settings/datenumberformatpipe';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [AuthNavbarComponent, AuthFooterComponent, MaterialAuthFullBGDirective, PopoverDirective,
    MainLeftsidebarComponent, MaterialSidebarBackgroudDirective, AgencyAuthNavbarComponent,ApexChartComponent,DateTimeNumberFormatPipe, DateNumberFormatPipe, SortDirective, ListFilterPipe],
  declarations: [AuthNavbarComponent, AuthFooterComponent, MaterialAuthFullBGDirective, PopoverDirective,
    MainLeftsidebarComponent, MaterialSidebarBackgroudDirective, AgencyAuthNavbarComponent,ApexChartComponent, DateTimeNumberFormatPipe, DateNumberFormatPipe, SortDirective, ListFilterPipe],
  providers: [],
})
export class SharedModule { }
