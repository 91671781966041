<!-- Left Sidebar -->
<aside id="leftsidebar" class="sidebar" *ngIf="userInfo">
    <div class="menu">
        <ul class="list">
            <li>
                <div class="user-info">
                    <div class="detail">
                        <h4>{{userInfo.last_name + ' ' + userInfo.first_name}}</h4>
                        <small>{{userInfo.email}}</small>
                    </div>
                    <a href="#" title="Events"><i class="zmdi zmdi-calendar"></i></a>
                    <a href="#" title="Inbox"><i class="zmdi zmdi-email"></i></a>
                    <a href="#" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>
                    <a href="#" title="Chat App"><i class="zmdi zmdi-comments"></i></a>
                    <a href="javascript:void(0)" (click)="logout()" title="Sign out"><i class="zmdi zmdi-power"></i></a>
                </div>
            </li>
            <li class="header">ỨNG DỤNG</li>
            <li class="active"> <a href="javascript:void(0);"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a>
            </li>
            <li> <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-shopping-cart"></i><span>Sàn
                        Thương Mại</span> </a>
                <ul class="ml-menu">
                    <li> <a href="">Sàn OCOP</a></li>
                    <li> <a href="">Sàn Dược Liệu</a></li>
                </ul>
            </li>
            <li> <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-delicious"></i><span>Truy
                        Xuất Nguồn Gốc</span> </a>
                <ul class="ml-menu">
                    <li><a href="">TXNN 2VN</a></li>
                    <li><a href="">Cổng OCOP</a></li>
                </ul>
            </li>
            <li class="header">HỔ TRỢ</li>
            <li class=""> <a href="javascript:void(0);"><i class="zmdi zmdi-phone"></i><span>LIÊN HỆ</span></a></li>
            <li class=""> <a href="javascript:void(0);"><i class="zmdi zmdi-phone"></i><span>GIỚI THIỆU</span></a>
            </li>
            <li class=""> <a href="javascript:void(0);"><i class="zmdi zmdi-phone"></i><span>TRUYỀN THÔNG</span></a>
            </li>

        </ul>
    </div>
</aside>