import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
@Directive({
    selector: '[popoverDirective]',
})
export class PopoverDirective {

    elementT: ElementRef;

    constructor(el: ElementRef) {
        this.elementT = el;
        $(this.elementT.nativeElement).popover({
            container: 'body'
        });
        // el.nativeElement.style.backgroundColor = 'yellow';
    }

}