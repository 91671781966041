import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MyScriptService } from '../../services/myscript.service';
import { LogoUrl } from '../../settings/configs';
declare var $: any;

@Component({
  selector: 'app-auth-agency-layout',
  templateUrl: './auth-agency-layout.component.html',
  styleUrls: ['./auth-agency-layout.component.css']
})
export class AuthAgencyLayoutComponent implements OnInit {


  logoUrl = LogoUrl;

  constructor(@Inject(DOCUMENT) private document: Document, private myScriptService: MyScriptService) { }


  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.createCssLink('maintheme', 'materialtheme.css');
    this.myScriptService.load( 'mdjs').then(data => {
      console.log('script loaded ', data);
    }).catch(error => console.log(error));
  }
  createCssLink(linkId, cssfile) {
    const headEl = this.document.getElementsByTagName('head')[0]

    const existEl = this.document.getElementById(linkId) as HTMLLinkElement;

    if (existEl) {
      existEl.href = cssfile;
    } else {
      const linkEl = this.document.createElement('link');

      linkEl.rel = 'stylesheet';
      linkEl.href = cssfile;
      linkEl.id = linkId;
      headEl.appendChild(linkEl);
    }

  }
}
