<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white">
    <div class="container">
        <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:;"><strong>TXNG Sản phẩm OCOP tỉnh LONG AN</strong></a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a routerLink="/auth/signin" class="nav-link">
                        <i class="material-icons">login</i>
                        {{'layout.layout_full.menu.login' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">
                        <i class="material-icons">lock_outline</i>
                        {{'layout.layout_full.menu.forgetpassword' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a href="https://2vn.vn" class="nav-link">
                        <i class="material-icons">contact_support</i>
                        {{'layout.layout_full.menu.contact' | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>