import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';

declare var ApexCharts: any;

export enum ChartType {
  bar = 1,
  freeStyle = 2
};

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.css']
})
export class ApexChartComponent implements OnInit, AfterViewInit, OnChanges {


  @Input('className')
  className = 'blue-graph';

  @Input('options')
  options: any;

  @Input('chart')
  chartType: any;

  @Input('series')
  series: any;

  @Input('xaxis')
  xaxis: any;

  @ViewChild('apexChartView')
  apexChartView: ElementRef;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

    //console.log(changes.options.currentValue);
    if (changes.chartType) {
      this.options = this.getUpOption(changes.chartType.currentValue);

      if (this.apexChartView) {
        var chart = new ApexCharts(
          this.apexChartView.nativeElement,
          this.options
        );

        chart.render();
      }
    }
  }

  ngAfterViewInit() {

    this.options = this.getUpOption(this.chartType);

    var chart = new ApexCharts(
      this.apexChartView.nativeElement,
      this.options
    );

    chart.render();
  }



  getUpOption(type) {
    if (type == ChartType.bar) {
      return {
        chart: {
          height: 300,
          type: 'bar',
          toolbar: {
            show: false,
            autoSelected: 'zoom'
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'arrow',
            columnWidth: '25%',
          },
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
        },
        stroke: {
          show: true,
          width: 0,
          colors: ['transparent']
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#f38559',
            shadeIntensity: 0.1
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100]
          }
        },
        series: this.series,
        xaxis: this.xaxis,
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      };
    } else if (type == ChartType.freeStyle) {
      return this.options;
    } else {
      return this.options;
    }
  }



}
