import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpOption, UrlAPIAccount, USER_LOCAL_KEY } from '../settings/configs';
import { UserObject } from '../models/data.models';
import { DGKLanguageService } from './language.service';
import { Subject } from 'rxjs';
import { deCryptAES, enCryptAES } from '../settings/dgkencrypt';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userKey = USER_LOCAL_KEY;

    UserSubject: Subject<UserObject> = new Subject<UserObject>();

    ActionSubject: Subject<number> = new Subject<number>();

    userInfo: UserObject | undefined;

    constructor(private router: Router, private httpClient: HttpClient, private languageService: DGKLanguageService) {

        var dataEnCrypted = localStorage.getItem(this.userKey);

        if (dataEnCrypted) {
            try {
                this.userInfo = JSON.parse(deCryptAES(dataEnCrypted));
            } catch {
                this.userInfo = undefined;
                localStorage.removeItem(this.userKey);
            }
        }

        this.UserSubject.next(this.userInfo);

    }

    changeLogin() {
        localStorage.removeItem(this.userKey);
        this.userInfo = undefined;
        this.UserSubject.next(this.userInfo);
        this.router.navigate(['/auth/signin']);
    }


    saveLogin(data: UserObject | undefined) {
        this.userInfo = data;
        var encrypt = enCryptAES(JSON.stringify(data));
        localStorage.setItem(this.userKey, encrypt);
        this.UserSubject.next(this.userInfo);
    }

    isLoggedIn(): boolean {
        return this.userInfo ? true : false;
    }

    sendLogin(username: any, password: any, token: string, account_type: number) {

        let url = UrlAPIAccount + '/auth/agency/signin';

        if (account_type == 4) {
            url = UrlAPIAccount + '/auth/agencystaff/signin';
        }

        return this.httpClient.post(url, {
            'username': username,
            'password': password,
            'grant_type': 'password',
        }, httpOption('', this.languageService.language_id, token));
    }

    sendShowLogin() {
        localStorage.removeItem(this.userKey);
        this.userInfo = undefined;
        this.UserSubject.next(this.userInfo);
        this.ActionSubject.next(1);
    }



    requestAuthorizationCode(client_id, request_id, redirect_uri, token) {
        return this.httpClient.post(UrlAPIAccount + '/auth/client/requestcode', {
            "client_id": client_id,
            "response_type": "code",
            "request_id": request_id,
            "redirect_uri": redirect_uri
        }, httpOption(this.userInfo.access_token, this.languageService.language_id, token));
    }


    signUp(data, captchaToken) {
        return this.httpClient.post(UrlAPIAccount + '/auth/enterprise/signup', data, httpOption('', this.languageService.language_id, captchaToken));
    }

    signUpAgency(data, captchaToken) {
        return this.httpClient.post(UrlAPIAccount + '/auth/agency/signup', data, httpOption('', this.languageService.language_id, captchaToken));
    }


    signUpAgencyStaff(data, captchaToken) {
        return this.httpClient.post(UrlAPIAccount + '/auth/agencystaff/signup', data, httpOption('', this.languageService.language_id, captchaToken));
    }


}