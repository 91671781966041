import { Component, OnInit } from '@angular/core';
import { UserObject } from '../../models/data.models';
import { UserService } from '../../services/user.service';
import { showAcceptAlert } from '../../settings/utils';

@Component({
  selector: 'app-main-leftsidebar',
  templateUrl: './main-leftsidebar.component.html',
  styleUrls: ['./main-leftsidebar.component.css']
})
export class MainLeftsidebarComponent implements OnInit {


  userInfo: UserObject;

  constructor(private userService: UserService) {
    this.userInfo = this.userService.userInfo;
    this.userService.UserSubject.subscribe((p: UserObject) => {
      this.userInfo = p;
    });
   }

  ngOnInit(): void {

  }

  logout() {
    showAcceptAlert('Alert', 'Do you want to sign out ?', () => {
      this.userService.changeLogin();
    });
  }

}
