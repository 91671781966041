import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { LogoUrl } from '../../settings/configs';
declare var $: any;
declare var md: any;

@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.css']
})
export class AuthNavbarComponent implements OnInit , AfterViewInit{

  logoUrl = LogoUrl;
  @Input('page')
  page = 1;

  constructor() { }

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    md.checkFullPageBackgroundImage();
  }
}
