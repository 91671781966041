import { Component } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dgk-account';


  ngAfterViewInit() {
   // $.getScript(`https://www.google.com/recaptcha/api.js?render=${reCATCHAKey}`);
  }
}
