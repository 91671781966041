import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LanguageObject } from '../models/data.models';

@Injectable({ providedIn: 'root' })
export class DGKLanguageService {

    language_id = 1;
    language_code = 'vi';

    languagePrefix = '';

    languageChange = new Subject<string>();

    languages: LanguageObject[] = [
        { "id": 1, "country_code": "VN", "language_code": "vi", "language_name": "VietNamese" },
        { "id": 2, "country_code": "EN", "language_code": "en", "language_name": "English" },
        { "id": 3, "country_code": "CN", "language_code": "zh", "language_name": "Chinese" },
        { "id": 4, "country_code": "CN-TW", "language_code": "zh-TW", "language_name": "Chinese (Traditional)" },
        { "id": 5, "country_code": "JP", "language_code": "ja", "language_name": "Japanese" },
        { "id": 8, "country_code": "KR", "language_code": "ko", "language_name": "Korean" },
        { "id": 9, "country_code": "RU", "language_code": "ru", "language_name": "Russian" },
        { "id": 11, "country_code": "TH", "language_code": "th", "language_name": "Thailand" }
    ];

    constructor(private translateService: TranslateService) {
        this.translateService.use(this.language_code);
    }

    changeLanguage(language_code: string) {
        this.language_id = this.findLanguageId(language_code);
        this.language_code = language_code;
        this.translateService.use(this.language_code);


        if (this.language_code === 'en') {
            this.languagePrefix = '_en';
        } else {
            this.languagePrefix = '';
        }

        this.languageChange.next(language_code);
    }

    findLanguageId(language_code: string) {
        for (var item of this.languages) {
            if (item.language_code === language_code) {
                return item.id;
            }
        }

        return 1;
    }

}