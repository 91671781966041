import { Component, Input, OnInit } from '@angular/core';
import { MyScriptService } from '../../services/myscript.service';
import { LogoUrl } from '../../settings/configs';
declare var md: any;

@Component({
  selector: 'app-agency-auth-navbar',
  templateUrl: './agency-auth-navbar.component.html',
  styleUrls: ['./agency-auth-navbar.component.css']
})
export class AgencyAuthNavbarComponent implements OnInit {

  logoUrl = LogoUrl;
  @Input('page')
  page = 1;

  constructor(private myScriptService: MyScriptService) { }

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    this.myScriptService.load('material').then(data => {
      md.checkFullPageBackgroundImage();
    }).catch(error => console.log(error));
  
  }

}
